<template>
  <div class="main">
    <div class="top">
      <p class="top_left">
        <i class="el-icon-back" style="margin-right: 20px;" @click="goBack"></i>
        <span>{{ title }}</span>
      </p>
      <p class="top_right">
        <span class="xinxin">*</span> 为必填项
      </p>
    </div>
    <el-divider></el-divider>
    <div class="content">
      <el-form :model="regitsterInfo" :rules="regitsterInfoRules" ref="regitsterInfoForm" label-width="180px">
        <div class="register-info">
          <div class="title">
            <span class="blue"></span>
            <p style="font-size:16px">流调登记信息</p>
          </div>
          <!-- {{regitsterInfo}} -->
          <div class="content-item">
            <el-form-item class="formitem" label="任务来源：" prop="taskSourceCode">
              <el-cascader v-if="echoAddress" class="select-width" ref="taskSourceRef"
                v-model="regitsterInfo.taskSourceCode" :props="{ ...props, checkStrictly: true }"
                @change="handleTaskSource" clearable></el-cascader>
            </el-form-item>
            <el-form-item class="formitem" label="调查人员：" prop="surveyUserName">
              <el-select v-model="regitsterInfo.surveyUserName" class="select-width">
                <el-option v-for="item in surveyUserNameSelect" :key="item.id" :label="item.name" :value="item.name"
                  @click.native="handleSurveyUserName(item)"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="事件名称：" prop="eventName">
              <el-select class="select-width" v-model="regitsterInfo.eventName" filterable clearable remote allow-create
                placeholder="请输入事件名称" :remote-method="remoteMethod" no-match-text="无匹配数据">
                <el-option v-for="item in eventNameSelect" :key="item.id" :label="item.eventName"
                  :value="item.eventName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="调查时间：" prop="surveyDate">
              <el-date-picker class="select-width" v-model="regitsterInfo.surveyDate" type="datetime" placeholder="选择时间"
                format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="调查场所：" prop="surveyAddress">
              <el-input v-model="regitsterInfo.surveyAddress" class="select-width" placeholder="请输入调查场所"></el-input>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="流调结果：" prop="surveyResult">
              <el-radio-group v-model="regitsterInfo.surveyResult" class="select-width">
                <el-radio v-for="item in surveyResult" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="说明：" prop="surveyResultDes">
              <el-input type="textarea" v-model="regitsterInfo.surveyResultDes" placeholder="请输入内容" maxlength="200"
                show-word-limit class="select-width"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="basic-info">
          <div class="title">
            <span class="blue"></span>
            <p style="font-size:16px">调查对象基本信息</p>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="姓名：" prop="name">
              <el-input v-model.trim="regitsterInfo.name" placeholder="请输入姓名" class="select-width" />
            </el-form-item>
            <el-form-item class="formitem" label="性别：" prop="sex">
              <el-radio-group v-model="regitsterInfo.sex" class="select-width">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="证件类型：" prop="cardType">
              <el-select v-model="regitsterInfo.cardType" placeholder="证件类型" class="select-width">
                <el-option v-for="item in typeCertificate" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="证件号：" prop="cardNo">
              <el-input class="select-width" v-model.trim="regitsterInfo.cardNo" placeholder="请输入证件号"
                @blur="idCodeCount(regitsterInfo.cardNo)" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="联系电话：" prop="linkPhone">
              <el-input class="select-width" maxlength="11" v-model.trim="regitsterInfo.linkPhone"
                placeholder="请输入联系电话" />
            </el-form-item>
            <el-form-item class="formitem" label="年龄：" prop="age">
              <el-input class="select-width" type="number" v-model="regitsterInfo.age" placeholder="请输入年龄" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="国籍：" prop="country">
              <el-select filterable placeholder="请选择国籍" class="select-width" v-model="regitsterInfo.country">
                <el-option v-for="item in nationalityselet" :key="item.label" :label="item.label" :value="item.label">
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">
                    {{
                        item.value
                    }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="民族：" prop="nation">
              <el-select class="top_left_inputnationality select-width" v-model="regitsterInfo.nation" placeholder="民族">
                <el-option v-for="item in nationSelect" :key="item.dictCode" :label="item.dictLabel"
                  :value="item.dictLabel"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="现住地：" prop="currentAddressCode">
              <el-cascader v-if="echoAddress" ref="currentAddressRef" class="select-width"
                v-model="regitsterInfo.currentAddressCode" :props="arearProps" @change="handelCurrentAddress">
              </el-cascader>
            </el-form-item>
            <el-form-item class="formitem" label="详细地址：" prop="currentAddressDetail">
              <el-input type="text" class="select-width" v-model="regitsterInfo.currentAddressDetail"
                placeholder="请输入详细地址"></el-input>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="人群分类：" prop="crowdTypeName">
              <el-select class="select-width" v-model="regitsterInfo.crowdTypeName" placeholder="选择人群分类">
                <el-option v-for="item in typepopSelect" :key="item.code" :label="item.ruleName"
                  :value="item.code" @click.native="handelCrowdType(item)"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label v-if="regitsterInfo.crowdType == 11">
              <el-input v-model="regitsterInfo.otherCrowdTypeName" placeholder="请输入具体人群分类" class="select-width" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="调查对象所属地区：" prop="surveyObjectBelongAreaCode">
              <el-cascader class="select-width" ref="surveyObjectBelongAreaRef"
                v-model="regitsterInfo.surveyObjectBelongAreaCode" v-if="echoAddress" :props="props"
                @change="handelSurveyObjectBelongArea" clearable></el-cascader>
            </el-form-item>
            <el-form-item class="formitem" label="所在社区：" prop="surveyObjectBelongOrg">
              <el-select class="top_left_inputnationality select-width" v-model="regitsterInfo.surveyObjectBelongOrg"
                placeholder="请选择" clearable>
                <el-option v-for="item in surveyObjectBelongOrgSelect" :key="item.orgCode" :label="item.orgName"
                  :value="item.orgName" @click.native="handelSurveyObjectBelongOrg(item)"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <!-- 7为次密 -->
      <template v-if="regitsterInfo.crowdType == 7">
        <relate-close-contact ref="closeContactRef"></relate-close-contact>
      </template>
      <template v-if="!!regitsterInfo.crowdType">
        <relate-positive-case ref="positiveCaseRef"></relate-positive-case>
      </template>

      <other-info ref="otherInfoRef" :formData="otherInfoData"></other-info>
    </div>
    <div class="btns">
      <el-button type="primary" @click="submitForm">提交</el-button>
      <el-button @click="resetForm">重置</el-button>
    </div>
    <!-- 提交对话框 -->
    <dialog-info :dialogVisible="dialogVisible" title="警告提示" @closeDialog="closeDialog" @confirmDialog="closeDialog">
      <el-row class="warning" type="flex" align="middle">
        <i class="el-icon-warning-outline" style="color:#ff9900;font-size:26px;font-weight:bold;margin-right:10px"></i>
        <span style="font-weight:bold;font-size:16px">当前保存用户疑似已入住隔离，请核实情况</span>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="3" :offset="2">{{ dialogMessage.name }}</el-col>
        <el-col :span="6">{{ dialogMessage.linkPhone }}</el-col>
        <el-col :span="6">{{ dialogMessage.org }}</el-col>
      </el-row>
    </dialog-info>
  </div>
</template>
<script>
import { http } from "@/api/index";
import {
  saveSurveyInfoApi,
  getSurveyInfoApi,
  getEventListApi,
  updateSurveyInfoApi,
  checkIsRegApi
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { getCrowdTypeList } from "@/api/RuleManagement/crowdAnalysis";
import { getMedicalOrgList, getUserListByPeopleTypeApi } from "@/api/SystemManagement/index";
import constants from "@/common/constants";
import { SURVEY_RESULT } from "@/common/constants";
import RelatePositiveCase from "./component/RelatePositiveCase.vue";
import RelateCloseContact from "./component/RelateCloseContact.vue";
import DialogInfo from "@/components/DialogInfo.vue";
import OtherInfo from "./component/OtherInfo.vue";
import { isIDCard, isPhone } from "@/utils/validate";
import { mapState } from "vuex";
import { getIdNumberInfo } from "@/utils/utils";

export default {
  name: "AddEpidemiologicalSurvey",
  components: { RelatePositiveCase, OtherInfo, RelateCloseContact, DialogInfo },
  props: {
    type: {
      type: String,
      default: "add"
    },
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    const phoneCheck = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };
    const cardNoCheck = (rule, value, callback) => {
      if (!isIDCard(value) && this.regitsterInfo.cardType == "1") {
        callback(new Error("请输入正确格式的身份证号"));
      } else {
        callback();
      }
    };
    return {
      title: "流调登记",
      echoAddress: false,
      dialogVisible: false, //对话框
      dialogMessage: {}, //对话框
      surveyUserNameSelect: [], //调查人员项
      eventNameSelect: [], //事件名称项
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), //国籍选项
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型
      surveyObjectBelongOrgSelect: [], //所属社区
      regitsterInfo: {
        taskSource: "",
        taskSourceCode: [],
        surveyUserName: JSON.parse(localStorage.getItem("userdoctor")).nickName,
        surveyUserId: JSON.parse(localStorage.getItem("userdoctor")).id,
        eventName: "",
        surveyDate: new Date(),
        surveyAddress: "",
        surveyResult: "3",
        surveyResultDes: "",
        name: "",
        sex: "",
        cardType: "1",
        cardNo: "",
        linkPhone: "",
        age: "",
        country: "中国大陆",
        nation: "汉族",
        currentAddress: "",
        currentAddressBdTuid: "",
        currentAddressCode: [],
        currentAddressDetail: "",
        currentAddressGdTuid: "",
        crowdType: "",
        crowdTypeName: "",
        otherCrowdTypeName: "",
        surveyObjectBelongArea: "",
        surveyObjectBelongAreaCode: [],
        surveyObjectBelongOrg: "",
        surveyObjectBelongOrgCode: ""
      },
      otherInfoData: {
        medicalStaff: "",
        workUnit: "",
        clinicalSymptoms: "",
        dateOfFirstSymptom: "",
        firstSymptom: [],
        firstSymptomOther: "",
        vaccination: "",
        vaccinationType: [],
        vaccinationTypeOther: "",
        inoculationNum: "",
        finishVaccination: "",
        basicDiseases: "",
        basicDiseasesName: [],
        otherBasicDiseasesName: ""
      },
      typepopSelect: [],
      regitsterInfoRules: {
        taskSourceCode: [
          { required: true, message: "请选择任务来源", trigger: "change" }
        ],
        surveyUserName: [
          { required: true, message: "请选择调查人员", trigger: "change" }
        ],
        surveyDate: [
          { required: true, message: "请选择调查时间", trigger: "change" }
        ],
        surveyAddress: [
          { required: true, message: "请输入调查场所", trigger: "blur" }
        ],
        surveyResult: [
          { required: true, message: "请选择流调结果", trigger: "change" }
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        cardType: [
          { required: true, message: "请选择证件类型", trigger: "change" }
        ],
        cardNo: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: cardNoCheck, trigger: "blur" }
        ],
        linkPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: phoneCheck, trigger: "blur" }
        ],
        currentAddressCode: [
          { required: true, message: "请输入现住地", trigger: "change" }
        ],
        currentAddressDetail: [
          { required: true, message: "请输入现住地详细地址", trigger: "blur" }
        ],
        crowdTypeName: [
          { required: true, message: "请选择人群分类", trigger: "change" }
        ],
        surveyObjectBelongOrg: [
          { required: true, message: "请选择所在社区", trigger: "change" }
        ]
      },
      //3级
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map(item => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3
              }));
              resolve(nodes);
            });
          }
        }
      },
      //4级
      arearProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 1000);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map(item => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 4
              }));
              resolve(nodes);
            });
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      nationSelect: state => state.user.nationSelect // 民族
    })
  },
  watch: {
    "regitsterInfo.currentAddressDetail"() {
      this.getBmap();
    },
    "regitsterInfo.currentAddress"() {
      this.getBmap();
    }
  },
  created() {
    //常量
    this.surveyResult = SURVEY_RESULT;
    this.echoAddress = this.type == "edit" ? false : true;
    if (this.type == "edit") {
      this.getSurveyInfo(this.id);
    }
    this.getUserListByPeopleType();
    this.getOrgQueryList();
    this.getCrowdTypeList();
  },

  methods: {
    // 返回上一页面
    goBack() {
      this.$parent.isShowAdd = false;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    //登记详情
    async getSurveyInfo(id) {
      const params = { id: id };
      const { data } = await getSurveyInfoApi({ params });
      if (data.code == "200") {
        this.regitsterInfo = data.data.survey;
        //处理省市区回填
        this.regitsterInfo.taskSourceCode = this.regitsterInfo.taskSourceCode
          ? this.regitsterInfo.taskSourceCode.split(",")
          : [];
        this.regitsterInfo.currentAddressCode = this.regitsterInfo
          .currentAddressCode
          ? this.regitsterInfo.currentAddressCode.split(",")
          : [];
        this.regitsterInfo.surveyObjectBelongAreaCode = this.regitsterInfo
          .surveyObjectBelongAreaCode
          ? this.regitsterInfo.surveyObjectBelongAreaCode.split(",")
          : [];
        this.echoAddress = true;
        //渲染其他信息
        this.renderOtherInfo();
        //渲染阳性病例、密接者组件数据
        const surveyRelationList = data.data.surveyRelationList;
        if (data.data.surveyRelationList.length == 0) return;
        let positiveCaseData = surveyRelationList.filter(item => {
          item.probabilityRelation =
            item.probabilityRelation == 1 ? true : false;
          return item.type == 0;
        });
        let closeContactData = surveyRelationList.filter(item => {
          item.probabilityRelation =
            item.probabilityRelation == 1 ? true : false;
          return item.type == 1;
        });
        if (positiveCaseData.length > 0) {
          this.renderPositiveCase("positiveCaseRef", positiveCaseData);
        }
        if (closeContactData.length > 0) {
          this.renderCloseContact("closeContactRef", closeContactData);
        }
      }
    },
    //渲染其他信息
    renderOtherInfo() {
      const {
        medicalStaff,
        workUnit,
        clinicalSymptoms,
        dateOfFirstSymptom,
        firstSymptom,
        firstSymptomOther,
        vaccination,
        vaccinationType,
        vaccinationTypeOther,
        inoculationNum,
        finishVaccination,
        basicDiseases,
        basicDiseasesName,
        otherBasicDiseasesName
      } = this.regitsterInfo;
      this.otherInfoData = {
        medicalStaff,
        workUnit,
        clinicalSymptoms,
        dateOfFirstSymptom,
        firstSymptom: firstSymptom ? firstSymptom.split(",") : [],
        firstSymptomOther,
        vaccination,
        vaccinationType: vaccinationType ? vaccinationType.split(",") : [],
        vaccinationTypeOther,
        inoculationNum,
        finishVaccination,
        basicDiseases,
        basicDiseasesName: basicDiseasesName
          ? basicDiseasesName.split(",")
          : [],
        otherBasicDiseasesName
      };
    },
    //渲染阳性病例
    renderPositiveCase(key, data) {
      this.$nextTick(() => {
        this.$refs[key].positiveCaseInfo = data;
        let tabData = data.map(item => {
          return {
            title: item.relationName,
            name: item.sort + "",
            data: item
          };
        });
        this.$refs[key].editableTabs = tabData;
        this.$refs[key].editableTabsValue = tabData[tabData.length - 1].name;
        this.$refs[key].tabIndex = Number(tabData[tabData.length - 1].name);
      });
    },
    //渲染密接者
    renderCloseContact(key, data) {
      this.$nextTick(() => {
        this.$refs[key].closeContactInfo = data;
        let tabData = data.map(item => {
          return {
            title: item.relationName,
            name: item.sort + "",
            data: item
          };
        });
        this.$refs[key].editableTabs = tabData;
        this.$refs[key].editableTabsValue = tabData[tabData.length - 1].name;
        this.$refs[key].tabIndex = Number(tabData[tabData.length - 1].name);
      });
    },
    //选择任务来源
    handleTaskSource() {
      let node = this.$refs.taskSourceRef.getCheckedNodes()[0];
      if (node) {
        let taskSource = node.pathLabels.toString();
        this.regitsterInfo.taskSource = taskSource;
      }
    },
    //获取事件列表
    async getEventList(val) {
      const params = { eventName: val };
      const { data } = await getEventListApi({ params });
      if (data.code == "200") {
        this.eventNameSelect = data.data;
      }
    },
    //获取事件名称列表
    async remoteMethod(query) {
      if (query !== "") {
        // this.loading = true;
        await this.getEventList(query);
        // this.loading = false;
      }
    },
    //获取调查人员
    async getUserListByPeopleType() {
      const params = { peopleType: 4 };
      const { data } = await getUserListByPeopleTypeApi({ params });
      if (data.code == "200") {
        this.surveyUserNameSelect = data.data;
      }
    },
    //选择调查人员
    handleSurveyUserName(item) {
      this.regitsterInfo.surveyUserName = item.name;
      this.regitsterInfo.surveyUserId = item.id;
    },
    //选择所属地区
    handelSurveyObjectBelongArea(value) {
      let node = this.$refs.surveyObjectBelongAreaRef.getCheckedNodes()[0];
      if (node) {
        let surveyObjectBelongArea = node.pathLabels.toString();
        this.regitsterInfo.surveyObjectBelongArea = surveyObjectBelongArea;
        this.regitsterInfo.surveyObjectBelongAreaCode = value;
      }
    },
    //现住地
    handelCurrentAddress(value) {
      let node = this.$refs.currentAddressRef.getCheckedNodes()[0];
      if (node) {
        let currentAddress = node.pathLabels.toString();
        this.regitsterInfo.currentAddress = currentAddress;
        this.regitsterInfo.currentAddressCode = value;
      }
    },

    // 获取组织架构列表
    async getOrgQueryList() {
      const { data } = await getMedicalOrgList();
      if (data.code == "200") {
        this.surveyObjectBelongOrgSelect = data.data;
      }
    },
    //选择人群分类
    handelCrowdType(item) {
      // console.log(item, "item");
      this.regitsterInfo.crowdType = item.code;
      this.regitsterInfo.crowdTypeName = item.ruleName;
    },
    //选择所属社区
    handelSurveyObjectBelongOrg(value) {
      this.regitsterInfo.surveyObjectBelongOrg = value.orgName;
      this.regitsterInfo.surveyObjectBelongOrgCode = value.orgCode;
    },
    
    // 身份证计算
    idCodeCount(id) {
      if (this.regitsterInfo.cardType == 1) {
        if (id && id.length == 18) {
          let obj = getIdNumberInfo(id);
          this.regitsterInfo.sex = obj.sex == 0 ? "1" : "0";
          this.regitsterInfo.age = obj.age;
        } else {
          this.regitsterInfo.sex = "";
          this.regitsterInfo.age = "";
        }
      }
    },
    //提交
    submitForm() {
      const registerValidate = this.$refs.regitsterInfoForm.validate();
      // console.log("registerValidate", registerValidate);
      let positiveCaseValidate = [];
      let closeContactValidate = [];
      if (!!this.regitsterInfo.crowdType) {
        positiveCaseValidate = this.$refs.positiveCaseRef.comm();
        if (this.regitsterInfo.crowdType == 7) {
          closeContactValidate = this.$refs.closeContactRef.comm();
          // console.log(closeContactValidate, "closeContactValidate");
        }
        // console.log(positiveCaseValidate, "positiveCaseValidat");
      }
      Promise.all([
        registerValidate,
        ...positiveCaseValidate,
        ...closeContactValidate
      ])
        .then(res => {
          if (!this.verifyFormInfo()) return;
          this.componentSubmit();
          this.checkIsReg();
        })
        .catch(res => {
          // console.log(res, "res");
          this.$message({
            message: "必填项未填写或填写格式不符合",
            type: "warning",
            offset: 60
          });
        });
    },
    //重置
    resetForm() {
      if (this.type == "add") {
        this.$refs.regitsterInfoForm.resetFields();
        this.$refs.otherInfoRef.resetf();
        this.regitsterInfo.crowdType = "";
        this.regitsterInfo.otherCrowdTypeName = "";
        this.regitsterInfo.surveyResultDes = "";
        return;
      }
      if (this.type == "edit") {
        this.getSurveyInfo(this.id);
      }
    },

    //提交业务逻辑校验
    verifyFormInfo() {
      const { surveyResult, surveyResultDes, crowdType } = this.regitsterInfo;
      //说明项
      if (surveyResult == 0 || surveyResult == 2) {
        if (!surveyResultDes) {
          this.$message.warning("流调结果为排除或居家隔离时需要填写说明");
          return false;
        }
      }
      //居家隔离
      if (surveyResult == 2) {
        if (crowdType != 7) {
          this.$message.warning("流调结果为居家隔离时只能是次密人员！");
          return false;
        }
      }
      return true;
    },
    //调用组件事件
    componentSubmit() {
      let { crowdType } = this.regitsterInfo;
      let survey, surveyRelationList;
      //  crowdType 7次密接  剩余其他
      if (crowdType == 7) {
        this.$refs.closeContactRef.commData();
        this.$refs.positiveCaseRef.commData();
        this.$refs.otherInfoRef.commData();
        surveyRelationList = [
          ...this.$refs.positiveCaseRef.positiveCaseInfo,
          ...this.$refs.closeContactRef.closeContactInfo
        ];
      } else {
        this.$refs.positiveCaseRef.commData();
        this.$refs.otherInfoRef.commData();
        surveyRelationList = this.$refs.positiveCaseRef.positiveCaseInfo;
      }
      //提交时数组数据处理
      survey = { ...this.regitsterInfo, ...this.$refs.otherInfoRef.otherInfo };
      if (survey.crowdType != 11) {
        survey.otherCrowdTypeName = "";
      }
      if (survey.surveyResult == 3) {
        survey.surveyResultDes = "";
      }
      survey.surveyDate = this.$moment(survey.surveyDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      survey.taskSourceCode =
        survey.taskSourceCode.length > 0
          ? survey.taskSourceCode.toString()
          : "";
      survey.currentAddressCode =
        survey.currentAddressCode.length > 0
          ? survey.currentAddressCode.toString()
          : "";
      survey.surveyObjectBelongAreaCode =
        survey.surveyObjectBelongAreaCode.length > 0
          ? survey.surveyObjectBelongAreaCode.toString()
          : "";
      survey.vaccinationType =
        survey.vaccinationType.length > 0
          ? survey.vaccinationType.toString()
          : "";
      survey.basicDiseasesName =
        survey.basicDiseasesName.length > 0
          ? survey.basicDiseasesName.toString()
          : "";
      survey.firstSymptom =
        survey.firstSymptom.length > 0 ? survey.firstSymptom.toString() : "";
      survey.dateOfFirstSymptom = !!survey.dateOfFirstSymptom
        ? this.$moment(survey.dateOfFirstSymptom).format("YYYY-MM-DD HH:mm:ss")
        : "";
      if (surveyRelationList.length > 0) {
        surveyRelationList.forEach(item => {
          item.dateOfOnset = !!item.dateOfOnset
            ? this.$moment(item.dateOfOnset).format("YYYY-MM-DD HH:mm:ss")
            : "";
          item.firstContactTime = !!item.firstContactTime
            ? this.$moment(item.firstContactTime).format("YYYY-MM-DD HH:mm:ss")
            : "";
          item.lastContactTime = !!item.lastContactTime
            ? this.$moment(item.lastContactTime).format("YYYY-MM-DD HH:mm:ss")
            : "";
          item.relationAddressCode =
            item.relationAddressCode.length > 0
              ? item.relationAddressCode.toString()
              : "";
          item.probabilityRelation = item.probabilityRelation == true ? 1 : 0;
        });
      }
      this.formInfo = { survey, surveyRelationList };
      // console.log(this.formInfo, "formInfo");
    },
    //校验是否已经流调登记
    async checkIsReg() {
      const params = this.formInfo;
      const { data } = await checkIsRegApi(params);
      if (data.code == "200") {
        this.type == "edit" ? this.updateSurveyInfo() : this.saveSurveyInfo();
      } else {
        this.dialogVisible = true;
        this.dialogMessage = data.data;
      }
    },
    //新增调查登记
    async saveSurveyInfo() {
      const params = this.formInfo;
      const { data } = await saveSurveyInfoApi(params);
      if (data.code == "200") {
        this.$message.success("提交成功！");
        this.goBack();
      }
    },
    //编辑调查登记
    async updateSurveyInfo() {
      const params = this.formInfo;
      const { data } = await updateSurveyInfoApi(params);
      if (data.code == "200") {
        this.$message.success("修改成功！");
        this.goBack();
      }
    },
    //获取百度经纬度
    getBmap() {
      this.$jsonp("https://api.map.baidu.com/geocoding/v3", {
        address:
          this.regitsterInfo.currentAddress +
          this.regitsterInfo.currentAddressDetail,
        output: "json",
        ak: "K47zbc0avMITSmQCoPu3oU9Eg9HLsII4"
      })
        .then(json => {
          this.regitsterInfo.currentAddressBdTuid =
            json.result.location.lng + "," + json.result.location.lat;
          const currentAddressGdTuid = this.bMapTransqqMap(
            json.result.location.lng,
            json.result.location.lat
          );
          this.regitsterInfo.currentAddressGdTuid =
            currentAddressGdTuid.lng + "," + currentAddressGdTuid.lat;
        })
        .catch(err => {
          // console.log(err);
        });
    },
    //百度转高德
    bMapTransqqMap(lng, lat) {
      const X_PI = (Math.PI * 3000.0) / 180.0;
      let x = lng - 0.0065;
      let y = lat - 0.006;
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
      let amap_lng = z * Math.cos(theta);
      let amap_lat = z * Math.sin(theta);
      return {
        lng: amap_lng,
        lat: amap_lat
      };
    },
    // 人群分类
    async getCrowdTypeList() {
      const { data } = await getCrowdTypeList();
      if (data.code == "200") {
        this.typepopSelect = data.data;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top_left {
      font-size: 18px;
      font-weight: bolder;
      color: #666666;
    }

    .top_right {
      font-size: 12px;
      color: #9a9a9a;

      .xinxin {
        color: #ff5050;
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.special {
  flex: 1;
}

.formitem {
  width: 50%;
}

.select-width {
  width: 80%;
}

.btns {
  display: flex;
  justify-content: center;
}

.warning {
  margin-bottom: 20px;
  padding-left: 20px;
}
</style>